// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../assets/images/main/backgroundMain.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main {
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___}) top no-repeat;
  background-size: cover;
  padding: 23px 15px 15px 15px;
  flex: 1 1;
}
.main__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.main__container_item {
  width: 100%;
  min-height: 70px;
  display: flex;
  align-items: center;
  border-radius: 10px;
  background: rgba(0, 0, 0, 0.73);
  margin-bottom: 10px;
}
.main__container_item img {
  padding-left: 25px;
  position: absolute;
  width: 40px;
  height: 40px;
}
.main__container_item p {
  width: 100%;
  color: #FFF;
  font-size: 25px;
}

@media screen and (max-width: 380px) {
  .main__container_item p {
    font-size: 22px;
  }
  .main__container a:nth-child(6) p {
    margin-left: 60px;
  }
}
@media screen and (max-width: 500px) {
  .main__container_item img {
    width: 20px;
    height: 20px;
  }
  .main__container_item p {
    font-size: 15px;
  }
}
@media (min-width: 501px) and (max-width: 850px) {
  .main__container_item img {
    width: 35px;
    height: 30px;
  }
  .main__container_item p {
    font-size: 21px;
  }
}`, "",{"version":3,"sources":["webpack://./src/pages/Main/Main.scss"],"names":[],"mappings":"AAAA;EACE,iEAAA;EACA,sBAAA;EACA,4BAAA;EACA,SAAA;AACF;AACE;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;AACJ;AACI;EACE,WAAA;EACA,gBAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,+BAAA;EACA,mBAAA;AACN;AACM;EACE,kBAAA;EACA,kBAAA;EACA,WAAA;EACA,YAAA;AACR;AAEM;EACE,WAAA;EACA,WAAA;EACA,eAAA;AAAR;;AASA;EACE;IACE,eAAA;EANF;EAYM;IACE,iBAAA;EAVR;AACF;AAgBA;EACE;IACE,WAAA;IACA,YAAA;EAdF;EAkBA;IACE,eAAA;EAhBF;AACF;AAmBA;EACE;IACE,WAAA;IACA,YAAA;EAjBF;EAqBA;IACE,eAAA;EAnBF;AACF","sourcesContent":[".main {\n  background: url(\"../../assets/images/main/backgroundMain.png\") top no-repeat;\n  background-size: cover;\n  padding: 23px 15px 15px 15px;\n  flex: 1;\n\n  &__container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n\n    &_item {\n      width: 100%;\n      min-height: 70px;\n      display: flex;\n      align-items: center;\n      border-radius: 10px;\n      background: rgba(0, 0, 0, 0.73);\n      margin-bottom: 10px;\n\n      img {\n        padding-left: 25px;\n        position: absolute;\n        width: 40px;\n        height: 40px;\n      }\n\n      p {\n        width: 100%;\n        color: #FFF;\n        font-size: 25px;\n      }\n    }\n  }\n}\n\n\n//media\n\n@media screen and (max-width: 380px) {\n  .main__container_item p {\n    font-size: 22px;\n  }\n\n  .main__container {\n    a {\n      &:nth-child(6) {\n        p {\n          margin-left: 60px;\n        }\n      }\n    }\n  }\n}\n\n@media screen and (max-width: 500px) {\n  .main__container_item img {\n    width: 20px;\n    height: 20px;\n  }\n\n\n  .main__container_item p {\n    font-size: 15px;\n  }\n}\n\n@media (min-width: 501px) and (max-width: 850px) {\n  .main__container_item img {\n    width: 35px;\n    height: 30px;\n  }\n\n\n  .main__container_item p {\n    font-size: 21px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
